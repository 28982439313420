import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { Link, Stack, Text } from "basis";

import { logger } from "../../../core";
import { useCustomerAuth } from "../../../core/auth";
import { isPurchaseDisabled } from "../../../core/config";
import { PROMO_SELECT_PLACEHOLDER } from "../../../core/constants";
import { useThreatMetrix } from "../../../hooks";

import {
  Container,
  ErrorMessage,
  ERROR_CODES,
  Flex,
  LoadingMessage,
  Profile,
} from "../../../components";
import {
  completePayment,
  PaymentMethod,
  CompletePayment,
  ROUTES,
  Scene,
  AccountErrors,
} from "../../../layout/wallet";
import { Promotions, useCustomerOrder } from "../../../layout/checkout";
import { retry } from "../../../utils/retry";

const Checkout = () => {
  const location = useLocation();
  const tm = useThreatMetrix();
  const [selectedAccount, setSelectedAccount] = useState();
  const [completingPayment, setCompletingPayment] = useState(false);

  const { logout } = useCustomerAuth();

  const {
    loading,
    disablePayment,
    error,
    order,
    promotions,
    selectedPromotion,
    changeSelectedPromotion,
    setErrorMessage,
    setDisablePayment,
    params,
  } = useCustomerOrder();

  const handlePromotionChange = (value) => {
    if (value === PROMO_SELECT_PLACEHOLDER) {
      setDisablePayment(true);
      return;
    }

    setDisablePayment(false);
    changeSelectedPromotion(value);
  };

  const handlePaymentClick = async () => {
    if (loading || completingPayment) {
      return;
    }

    setCompletingPayment(true);

    try {
      const { url } = await retry({
        fn: () =>
          completePayment(
            params.transactionId,
            selectedPromotion?.value,
            selectedAccount,
            0,
            tm.getSessionId()
          ),
        onRetryBegin: (numAttempt) =>
          numAttempt > 0 &&
          logger.info(`handlePaymentClick(): retry #${numAttempt}`, {
            params,
            paymentResponse: {
              promotion: selectedPromotion?.value,
              selectedAccount,
            },
          }),
        attempts: 3,
        sleepMs: 200,
        sleepMsDelay: [800, 1200, 1400],
      });

      window.location.replace(url);
    } catch (err) {
      logger.error("handlePaymentClick(): failed", { params, err });

      setErrorMessage(
        `We could not update your payment request. Ref # ${params.transactionId}`
      );
    }
  };

  const handleManualPaymentClick = async () => {
    if (loading || completingPayment) {
      return;
    }

    setCompletingPayment(true);
    window.location.replace(`${ROUTES.MANUAL_PAYMENT}${location.search}`);
  };

  if (loading) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <LoadingMessage message="Getting transaction details while you wait .." />
      </Scene>
    );
  }

  if (isPurchaseDisabled) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <ErrorMessage type={ERROR_CODES.PURCHASE_DISABLED} />
      </Scene>
    );
  }

  if (error?.message) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }

  return (
    <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Flex>
            <Link href={order?.urlCancel} newTab={false} blue>
              &lt; Back to cart
            </Link>

            <Link href="#" onClick={logout} newTab={false}>
              Log out
            </Link>
          </Flex>

          <Text align="left" textStyle="heading4">
            Complete your order
          </Text>
        </Stack>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>
          <Flex>
            <Text>
              <strong>Your purchase</strong>
            </Text>
            <Text>${order?.amount}</Text>
          </Flex>
        </Container>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>
          <Profile firstName={order?.givenName} />

          <Promotions
            title="Interest Free Plan"
            loading={loading || completingPayment}
            promotions={promotions}
            selectedPromotion={selectedPromotion}
            onChange={handlePromotionChange}
            order={order}
          />

          <PaymentMethod
            loading={completingPayment}
            accounts={order?.accounts}
            onChange={setSelectedAccount}
          />

          <AccountErrors
            errors={order?.accountErrors}
            selectedAccount={selectedAccount}
          />

          <CompletePayment
            disabled={disablePayment}
            isAccountSelected={Boolean(selectedAccount?.isAvailable)}
            isCompletingPayment={completingPayment}
            allowWalletPayment={order?.accounts?.length > 0}
            onPaymentClick={handlePaymentClick}
            onManualPaymentClick={handleManualPaymentClick}
            country={order?.country}
          />
        </Container>
      </Container>
    </Scene>
  );
};

export default Checkout;
